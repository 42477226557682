import { ComponentRef } from "@angular/core";
import { Base, LiveEvent } from "@zixi/models";
import { KeyMap } from "../../../models/shared";
import { FunctionRef, ZxEditTableRowButtonsComponent } from "./zx-edit-table-row-buttons.component";

type BooleanCallback<T = KeyMap<Base>> = (row: T) => boolean;

export interface EditTableRowInputs<T = LiveEvent, R = void> {
    canDeleteCallBack?: BooleanCallback<T>;
    canEditCallBack?: BooleanCallback<T>;
    canCloneCallBack?: BooleanCallback<T>;
    canEnableCallBack?: BooleanCallback<T>;
    canToggleSlateCallBack?: BooleanCallback<T>;
    editRef?: FunctionRef<T>;
    cloneRef?: FunctionRef<T>;
    enableRef?: FunctionRef<T>;
    deleteRef?: FunctionRef<T, R>;
    toggleSlateRef?: FunctionRef<T>;
}

export const assignEditTableRowInputsFactory =
    <T = LiveEvent, R = void>(inputs: EditTableRowInputs<T, R>) =>
    (ComponentRef: ComponentRef<ZxEditTableRowButtonsComponent>, row: KeyMap<T>) => {
        const comp = ComponentRef.instance;
        comp.canEdit = inputs.canEditCallBack && inputs.canEditCallBack(row);
        comp.canDelete = inputs.canDeleteCallBack && inputs.canDeleteCallBack(row);
        comp.canClone = inputs.canCloneCallBack && inputs.canCloneCallBack(row);
        comp.canEnable = inputs.canEnableCallBack && inputs.canEnableCallBack(row);
        comp.canToggleSlate = inputs.canToggleSlateCallBack && inputs.canToggleSlateCallBack(row);
        comp.editRef = inputs.editRef;
        comp.cloneRef = inputs.cloneRef;
        comp.enableRef = inputs.enableRef;
        comp.deleteRef = inputs.deleteRef;
        comp.toggleSlateRef = inputs.toggleSlateRef;
        comp.row = row;
    };
